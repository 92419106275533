.header {
  &-hero {
    text-align: center;
    white-space: pre-wrap;
    transition-timing-function: ease;
    transition-duration: 0.45s;
    transition-delay: 0.0142668s;
    line-height: 1.2em;
    font-weight: 700 !important;
    font-style: normal;
    transition-property: transform, opacity;
    opacity: 1 !important;
    font-family: Work Sans !important;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    white-space: pre-wrap;
  }
}
