.job-card {
  box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
    -2px -2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 32px;
  margin: 8px;
  background-color: #1f2227;
  background: transparent linear-gradient(112deg, #161a21 0%, #201d39 100%) 0%
    0% no-repeat padding-box;

  img {
    width: 72px;
    border-radius: 100%;
    background-color: #1f2227;
  }

  &-top-row {
    width: 25%;
    float: right;
    text-align: right;
    display: inline-block;
    margin-top: -28px;

    &-left {
    }
  }

  &-logo {
    float: left;
    display: inline-block;
    padding-right: 16px;
    width: 72px;
  }

  &-company {
    display: inline-block;
    font-weight: 600;

    &-title {
      margin-bottom: -15px;
      font-weight: 600;
    }
  }

  &-view-profile {
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
  }

  &-row-right {
  }

  &-cta {
    font-weight: 600;
    margin-bottom: -12px;
    padding-top: 16px;
  }

  &-post-date {
    text-align: right;
    font-size: 13px;
  }

  &-category-container {
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;

    p {
      margin-bottom: 0px;
    }
  }

  &-location {
    margin-top: -16px;
  }

  &-message {
    box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
      -2px -2px 8px rgba(0, 0, 0, 0.4);
    padding: 32px;
  }

  &-title {
    padding-top: 16px;
    text-transform: uppercase;
    font-size: 14px;
  }

  &-sector {
    float: left;
    display: inline-block;
  }

  &-technology {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    display: inline-block;

    span {
        background-color: #7c7c7c;
        padding: 12px 14px;
        border-radius: 80px;
        line-height: 40px;
      }
  }

  &-focus {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .job-card-top-row-left {
    width: 75%;
    float: left;
    display: inline-block;
  }
}

@media only screen and (min-width: 767px) {
  .job-card-category-container {
    width: 100%;
    padding-bottom: 12px;
  }
}
@media only screen and (max-width: 766px) {
  .job-card-category-container {
    width: 100%;
    height: auto;
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 768px) {
    .job-card-location-section {
      width: 50%;
      float: left;
      display: inline-block;
    }

}