.nav-link {
  font-family: "Work Sans" !important;
}

.MuiSelect-select, .MuiMenuItem-gutters {
  font-family: "Work Sans" !important;
}

body {
  color: #fdfdfd;
  overflow: hidden;
  font-family: "Work Sans" !important;
}

button {
  font-family: "Work Sans" !important;
}

a {
  text-decoration: none;
  font-family: "Work Sans" !important;
}
