.membership-input {
  background: #ffffff14 0% 0% no-repeat padding-box;
  border: 1px solid #888888 !important;
  border-radius: 8px !important;
  opacity: 1;
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff !important;
  font-family: Work Sans;
  padding: 0 24px;
  flex: 1;

  &::placeholder {
    color: #fff !important;
    font-family: Work Sans;
  }
}

.membership-textarea {
  background: #ffffff14 0% 0% no-repeat padding-box;
  border: 1px solid #888888 !important;
  border-radius: 8px !important;
  opacity: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff !important;
  font-family: Work Sans;
  padding: 10px 24px;
  flex: 1;

  &::placeholder {
    color: #fff !important;
    font-family: Work Sans;

  }
}

.membership-button {
  background: #ffffff14 0% 0% no-repeat padding-box;
  border: 1px dashed #98999d;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  //   width: 100%;
  flex: 1;
  cursor: pointer;
}

.membership-button-signup {
  background: #ffffff14 0% 0% no-repeat padding-box;
  border: 1px dashed #98999d;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 149px;
  
  cursor: pointer;
}


.membership-flex {
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
}

.membership-flex-logobox {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

