.company-object {
  &-result {
  }

  &-media-container {
  }

  &-title {
    margin: 0;
    font-size: calc((1.2 - 1.15) * 1.2vh + 1rem);
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
  }

  &-link {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    color: inherit;
    text-decoration: none;
    color: #fdfdfd;
    font-size: calc((1.2 - 1) * 1.2vh + 1rem);
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.8em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.6em;
    font-size: 1rem;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }

  &-sector-focus {
    margin: 10px 0;
  }

  &-location {
    display: block;
    color: #fdfdfd;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: none;
    line-height: 1.6em;
    font-size: 1rem;
    line-height: 1.6;
    font-size: calc((1.2 - 1) * 1.2vh + 1rem);
    margin: 0;
  }
}
