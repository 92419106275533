.hero {
  font-family: Work Sans;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  min-height: 32vh;
  padding-top: 122.453px;
  padding-bottom: 122.453px;

  align-items: center;
  color: #fdfdfd;
  background-color: #161a21;
  position: relative;
  display: flex;
  pointer-events: none;
  padding-right: 72px;
  padding-left: 72px;

  &-feature-text {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    white-space: pre-wrap;
    transition-timing-function: ease;
    transition-duration: 0.45s;
    transition-delay: 0.0151798s;
    margin-bottom: 0;
    word-wrap: break-word;
    color: #fdfdfd;
    opacity: 1 !important;
    transition-property: opacity;
    font-size: calc((1.2 - 1) * 1.2vh + 1rem);
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}
