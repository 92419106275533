@import "./styles/navigation.scss";
@import "./styles/footer.scss";

@import "./styles/headers.scss";
@import "./styles/hero.scss";
@import "./styles/company-card.scss";

@import "./styles/horizontal-scroll.scss";
@import "./styles/job-card.scss";

@import "./styles/form.scss";
@import "./styles/button.scss";

@import "./styles/pages/membership-conversion.scss";

@import "./styles/animations.scss";

p {
  font-family: "Work Sans";
}

#header-hero-2 {
  margin-top: 0px;
  // margin-bottom: 10px;
  font-family: sans-serif;
  font-size: 6rem;
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 700 !important;
  font-family: "Work Sans" !important;
}

.authed-subnavigation {
  &-link.active {
    .authed-subnavigation-text {
      color: #e9b82a !important;
    }
  }
}

/*Insights Promo*/
.insights-promo {
  background-image: linear-gradient(
    180deg,
    rgba(72, 156, 188, 1),
    rgba(23, 191, 185, 1)
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px;
  border-radius: 8px;
}
.insights-promo-body-container {
  padding-bottom: 24px;
}
.insights-promo-email-input {
  border: none;
  background-color: transparent;
  background: transparent !important;
  border-bottom: 1px solid #ffffff;
  height: 32px;
  padding: 8px 0px 8px 0px;
}
.insights-promo-email-input::placeholder {
  color: #ffffff;
}
.insights-promo-email-input::focus {
  color: #ffffff;
}
.insights-promo-subscribe-button {
  background-color: rgba(255, 255, 255, 0.24);
  border: none;
  padding: 12px 20px;
  border-radius: 80px;
  margin-top: 16px;
  color: #ffffff;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  #block-yui_3_17_2_1_1637445692032_15311,
  #block-yui_3_17_2_1_1637746805356_9375,
  #block-yui_3_17_2_1_1637445692032_16664,
  #block-yui_3_17_2_1_1637746716755_3745,
  #block-yui_3_17_2_1_1637445692032_14618,
  #block-yui_3_17_2_1_1637746805356_5215 {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  #block-yui_3_17_2_1_1638366633176_8404,
  #block-yui_3_17_2_1_1638366633176_8998 {
    display: none;
  }
}
/*Company Settings Page*/
.premium-only-banner {
  background-color: #e9b82a;
  text-align: center;
  font-weight: 600;
  padding: 8px;
  border-radius: 160px;
}
.logoImage {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  background-color: #161a21;
  margin: auto;
  margin-bottom: 24px;
}
.logo-image-placeholder {
  font-size: 48px !important;
  padding-top: 34px;
  padding-left: 34px;
  color: #98999d;
}
.company-profile-settings-company-name {
  padding-top: 32px;
  font-size: 2.8rem;
  font-weight: 600;
  text-align: center;
  background-color: #1f2227 !important;
  border-bottom: 1px solid #ffffff !important;
}

/*Company Profile Pricing Page*/
.pricing-card {
  min-height: 376px;
  padding-top: 48px !important;
}
.pricing-card-header-container {
  padding-bottom: 16px;
}
.pricing-card-header-container-left {
  display: inline-block;
}
.pricing-card-header-container-right {
  display: inline-block;
  text-align: right;
  float: right;
}
.pricing-card-header-container-left h3,
.pricing-card-header-container-right h3 {
  line-height: 0px;
  margin: -2px;
}
.pricing-card-price-interval {
  color: #dbdbdb;
}
.job-allowance-select {
  border-bottom: 1px solid #ffffff;
}
#job-allowance-select,
#job-allowance-select-footer {
  background: none;
}
.pricing-card-benefits-list ul {
  padding: 0;
  list-style-type: none;
}
.pricing-card-benefits-list-checkmark {
  color: #e9b82a;
  background-color: rgba(101, 83, 232, 0.24);
  padding: 8px;
  border-radius: 100%;
  margin-right: 8px;
}
#membership-price {
  text-decoration: line-through;
}
/*Registration Forms*/
.form-subtext {
  color: #dbdbdb;
  font-size: 1rem;
  padding-bottom: 16px;
}
.registration-form-input {
  background-color: #2d2e3d !important;
  padding: 14px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #888888 !important;
  border-radius: 8px !important;
  width: 96% !important;
  margin-bottom: 16px;
}
.form-button {
  font-size: 1rem;
  font-weight: 600;

  word-wrap: break-word;
  color: #a3a3a3;
  font-family: proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
.exp-date,
.cvv {
  width: 112px !important;
  display: inline-block;
  color: #ffffff;
}
.cc-number {
  color: #ffffff;
}
.sub-field-text {
  font-size: 11px;
  margin-top: -8px;
  margin-bottom: 16px;
}
/*Premium Company Profile*/
.company-profile-header-info {
  padding-bottom: 16px;
}
.company-profile-header-logo-container {
  display: inline-block;
  float: left;
  width: 96px;
  height: 96px;
}
.company-profile-header-logo {
  width: 96px;
  height: 96px;
  border-radius: 100%;
}
.company-profile-header-company-name-container {
  display: inline-block;
  padding-left: 16px;
}
.company-profile-header-company-name {
  font-size: 2.8rem;
  margin-bottom: -4px;
  display: inline-block;
}
.company-profile-header-social-links-container {
  font-weight: 600;
  margin-top: 0px;
}
.linkedin-link {
  color: #0077b5;
  margin-left: 12px;
}
.twitter-link {
  color: #1da1f2 !important;
  margin-left: 12px;
}
.profile-job-object {
  flex: 0 0 auto;
  width: 560px;
  margin-right: 40px;
}
@media only screen and (max-width: 720px) {
  .profile-job-object {
    width: 72vw !important;
  }
  .profile-card-category-container {
    height: 80px;
  }
  .profile-card-category-container p {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 719px) {
  .card-cta-company-profile {
    text-align: right;
    width: 100%;
  }
}
[data-section-id="61849725b097ed7f9ca4519a"].page-section:not(.full-bleed-section)
  > .content-wrapper {
  padding-right: 0px;
}
.company-profile-header-problem-statement-container {
  font-size: 1.6rem;
  float: left;
}
.company-headcount-container {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
}
.company-details-heading {
  font-weight: 600;
  font-size: 1.5rem;
}
.company-details-container table {
  width: 100%;
}
.company-details-container tr {
  border-bottom: 2px solid #ffffff;
}
.company-details-container td {
  padding: 8px 0px 8px 0px;
}
.company-details-container tr:last-child {
  border-bottom: none;
}
.company-funding-round {
  font-weight: 600;
}
.company-document-filename {
  font-weight: 600;
  margin-bottom: -12px;
}
.company-funding-round-date {
  text-align: right;
  color: #dbdbdb;
}
.company-document-date {
  text-align: right;
  color: #dbdbdb;
  vertical-align: top;
  padding-top: 22px;
}
.company-document-presentation {
  color: #ff6453;
  font-weight: 500;
  font-size: 1.1rem;
}
.company-document-image {
  color: #df2dd4;
  font-weight: 500;
  font-size: 1.1rem;
}
.company-document-document {
  color: #6abbf4;
  font-weight: 500;
  font-size: 1.1rem;
}
.leadership-icon-container {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: inline-block;
  float: left;
  padding-right: 8px;
}
.leadership-icon-container img {
  border-radius: 100%;
}
.leadership-details-container {
  display: inline-block;
  height: 80px;
}
.leadership-details-container p {
  margin-top: 0px;
  margin-bottom: -4px;
  vertical-align: middle;
}
.leadership-name {
  font-weight: 600;
}
.company-profile-company-name-placeholder {
  width: 240px;
  height: 48px;
  background-color: #161a21;
  border-radius: 8px;
  margin-top: -16px;
}
/*Company Search Results*/
.company-object-result-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

// .test {
//   grid-template-columns:
// }
.company-object-result {
  flex: 0 0 300px;
}
.company-object-result iframe {
  border-radius: 8px;
}
.company-object-result-container .skeleton-card {
  padding: 0;
  flex: 0 0 30%;
}
@media only screen and (max-width: 968px) {
  .company-object-result-container .skeleton-card {
    flex: 0 0 100% !important;
  }
  .company-object-result {
    flex: 0 0 100% !important;
    width: unset !important;
  }
}

/* Main Search Bar*/
@media only screen and (min-width: 1200px) {
  .search-filter-flex-container {
    display: flex;
    margin: auto;
  }
}
.search-filter-flex-container-component,
.search-filter-flex-container-component-companies {
  background-color: rgba(255, 255, 255, 0.08);
  border: solid 1px #888888;
  padding: 14px 12px;
  font-size: 14px;
}
.search-placeholder {
  white-space: nowrap;
  vertical-align: baseline;
  margin: 0px;
}
::placeholder {
  opacity: 0.8;
}
:-ms-input-placeholder {
  opacity: 0.8;
}
::-ms-input-placeholder {
  opacity: 0.8;
}
@media only screen and (max-width: 1199px) {
  .search-filter-flex-container-component,
  .search-filter-flex-container-component-companies {
    border-radius: 8px;
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .search-filter-flex-container-component:first-child,
  .search-filter-flex-container-component-companies:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .search-filter-flex-container-component:last-child,
  .search-filter-flex-container-component-companies:last-child {
    border-radius: 0px 8px 8px 0px;
  }
  .search-filter-flex-container-component:nth-child(1) {
    width: 40%;
  }
  .search-filter-flex-container-component:nth-child(2) {
    width: 20%;
  }
  .search-filter-flex-container-component:nth-child(3) {
    width: 20%;
  }
  .search-filter-flex-container-component:nth-child(4) {
    width: 20%;
  }
  .search-filter-flex-container-component:nth-child(5) {
    width: 15%;
  }
  .search-filter-flex-container-component-companies:nth-child(1) {
    width: 38%;
  }
  .search-filter-flex-container-component-companies:nth-child(2) {
    width: 25%;
  }
  .search-filter-flex-container-component-companies:nth-child(3) {
    width: 25%;
  }
  .search-filter-flex-container-component-companies:nth-child(4) {
    width: 12%;
  }
}
.search-filter-flex-container-component:last-child,
.search-filter-flex-container-component-companies:last-child {
  background-image: linear-gradient(180deg, #7b69ff, #5442d6);
  border: none;
  padding: 0px;
  margin-bottom: 0px;
}
#submit-search {
  color: #ffffff;
  font-weight: 600;
}
.form-subtext-error,
.form-subtext-account-error {
  color: red !important;
  display: block !important;
}

#form-subtext-error,
#form-subtext-account-error {
  display: none;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: none;
  color: #ffffff;
}
input[type="text"],
input[type="email"],
input[type="password"] {
  background-color: #282c31;
  width: 90%;
}

.search-company-jobs-bar input[type="text"] {
  width: 100%;
  background-color: #161a21;
  border-bottom: 2px solid #fff;
  color: rgb(255 255 255 / 82%);
}
 
.search-company-jobs-bar input[type="text"]:hover:not(:focus) {
  border-bottom: 4px solid #1976d2;
}
input[type="submit"] {
  width: 90%;
  background-color: transparent;
}
input[type="date"],
input[type="date"]:focus {
  color: #ffffff;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
input[type="text"] :focus,
input[type="text"] :focus,
input[type="email"] :focus,
input[type="password"],
input[type="submit"] :focus {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: #282c31;
  border: none;
  color: #ffffff;
}
.dark :focus,
.dark a:focus {
  outline-style: none !important;
  outline-color: none !important;
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: #282c31;
  border: none;
}
input,
select,
textarea {
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
.search-filter-flex-container-component select,
.search-filter-flex-container-component-companies select {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #282c31;
  width: 100%;
}
.search-filter-flex-container-component select:focus,
.search-filter-flex-container-component-companies select:focus {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #282c31;
}
.below-search {
  padding-top: 8px;
}
#clear-search-link {
  color: #e9b82a;
  margin-right: 12px;
  float: left;
}
#results-number {
  float: right;
}

[data-section-id="614b80a53f38bd478b118808"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="614cf842c2aef903557677ec"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="614cf836a63ab94e01662bcd"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="617b4a117f3c695231d31822"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="617b4a117f3c695231d31824"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="617b4a117f3c695231d31826"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="617b5cdb1cdbab6e368b4656"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="617b5d0f259cbb499198654f"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="6183f0cb9dc90f3ad7c7bb18"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="6183f0f89dc90f3ad7c7c016"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="6183f11b0257c81a1f04d4ac"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="6183f12ddeb0e63272ad498f"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="6183f14c9f3e3963f9b957db"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601ee"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601f0"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="619cdc9d575b412e2e58b965"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601f4"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601f6"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601f8"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601fa"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b0601fc"].page-section:not(.full-bleed-section)
  > .content-wrapper,
[data-section-id="618a559f5d7ceb394b060200"].page-section:not(.full-bleed-section)
  > .content-wrapper {
  padding-right: 0px;
}
.profile-job-object {
  flex: 0 0 auto;
  width: 560px;
  height: auto;
  margin-right: 40px;
}
.company-object {
  flex: 0 0 auto;
  width: 360px;
  height: auto;
  margin-right: 40px;
}
.company-object iframe {
  width: 280px;
  border-radius: 16px;
  object-fit: cover;
}
.company-search-company-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: -10px;
}
.company-search-company-sector {
  padding-top: 4px;
}

.article-object {
  flex: 0 0 auto;
  width: 360px;
  height: auto;
  margin-right: 40px;
}
.article-object-suggestion {
  width: 100%;
  height: auto;
  margin: auto;
}
.article-object img {
  width: 360px;
  height: 480px;
  border-radius: 16px;
  object-fit: cover;
  margin-bottom: 16px;
}
.article-object-suggestion img {
  width: 300px;
  height: 400px;
  border-radius: 16px;
  object-fit: cover;
  margin-bottom: 16px;
}
@media only screen and (max-width: 771px) {
  .article-object {
    flex: 0 0 auto;
    width: 280px;
    height: auto;
    margin-right: 40px;
  }
  .article-object img {
    width: 280px;
    height: 320px;
    border-radius: 16px;
    object-fit: cover;
    margin-bottom: 16px;
  }
}
.article-object-title h4 {
  font-size: 18px;
}
.article-object-title p {
  font-size: 14px;
  margin-top: -16px;
}
.insights-subscribe-button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: 0px;
}

/* Marketing Page */
.search-cta {
  font-size: 1.3rem;
  font-weight: 600;
}
.search-cta a {
  margin-right: 8px;
  margin-left: 8px;
  transition: 0.5s;
}
.search-cta a:hover {
  color: #e9b82a;
  transition: 0.5s;
}
.svg-gradient-text-container svg {
  width: 100%;
  height: auto;
}
.homepage-technology-chips span {
  margin-right: 4px;
}
#block-0ff3cf5d14a927cf7939 h1 {
  line-height: 1.2em;
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    99.38% 50%,
    color-stop(0, rgba(39, 255, 255, 1)),
    color-stop(0.255, rgba(1, 255, 51, 1)),
    color-stop(0.509, rgba(143, 228, 142, 1)),
    color-stop(0.755, rgba(228, 113, 45, 1)),
    color-stop(1, rgba(211, 0, 202, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#27FFFF', endColorstr='#D300CA' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#27FFFF',endColorstr='#D300CA' , GradientType=1);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}
#typewriter-text {
  background: -moz-linear-gradient(
    0% 50% 0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0% 50%,
    99.38% 50%,
    color-stop(0, rgba(39, 255, 255, 1)),
    color-stop(0.255, rgba(1, 255, 51, 1)),
    color-stop(0.509, rgba(143, 228, 142, 1)),
    color-stop(0.755, rgba(228, 113, 45, 1)),
    color-stop(1, rgba(211, 0, 202, 1))
  );
  background: -o-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  background: -ms-linear-gradient(
    0deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#27FFFF', endColorstr='#D300CA' ,GradientType=0)";
  background: linear-gradient(
    90deg,
    rgba(39, 255, 255, 1) 0%,
    rgba(1, 255, 51, 1) 25.5%,
    rgba(143, 228, 142, 1) 50.9%,
    rgba(228, 113, 45, 1) 75.5%,
    rgba(211, 0, 202, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#27FFFF',endColorstr='#D300CA' , GradientType=1);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/* Homepage Hero */
.h1-hero-text {
  margin-bottom: -28px;
}
.profile-media-container {
  overflow: auto;
}
.hero-typewriter {
  height: 100px;
}
@media screen and (max-width: 767px) {
  .hero-typewriter h4 {
    font-size: 40px !important;
  }
}
.homepage-jobs {
  padding-top: 16px;
}
.homepage-card-technology {
  display: inline-block;
  margin-bottom: -4px;
}
.homepage-card-technology {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: inline-block;
}
.homepage-card-technology span {
  background-color: #7c7c7c;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

/* Featured Companies */
.featured-company-logo {
  width: 48px;
  padding-right: 8px;
  display: inline-block;
}
.featured-company-logo-temp {
  width: 80px;
  margin: auto;
}
.featured-company-logo-temp img {
  border-radius: 100%;
}
.featured-company-logo img {
  border-radius: 100%;
}

/* Job Board */
@media only screen and (min-width: 768px) {
  .search-filter-sort-container {
    width: 320px;
    position: fixed;
  }
}
li {
  list-style: none;
  padding-bottom: 16px;
}

/* Skeleton Cards */
.skeleton-card {
  box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
    -2px -2px 8px rgba(0, 0, 0, 0.4);
  padding: 32px;
  margin-bottom: 24px;
  background-color: #1f2227;
  height: 224px;
  background: transparent linear-gradient(112deg, #161a21 0%, #201d39 100%) 0%
    0% no-repeat padding-box;
}
.skeleton-company-card {
  margin-bottom: 112px;
}
.skeleton-wrapper-inner {
  height: 150px;
  padding: 15px;
  position: relative;
}
.skeleton-company-wrapper-inner {
  height: 260px;
  position: relative;
  border-radius: 8px !important;
  margin-bottom: 80px;
}
.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #373d46;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#373d46),
    color-stop(0.2, #424a55),
    color-stop(0.4, #373d46),
    to(#373d46)
  );
  background-image: -webkit-linear-gradient(
    left,
    #373d46 0%,
    #424a55 20%,
    #373d46 40%,
    #373d46 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  // background-image: -webkit-linear-gradient(
  //   135deg,
  //   red 0%,
  //   orange 15%,
  //   yellow 30%,
  //   green 45%,
  //   blue 60%,
  //   indigo 75%,
  //   violet 80%,
  //   red 100%
  // );
  background-repeat: repeat;
  background-size: 50% auto;
}

.skeleton-wrapper-body div {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
}

div.skeleton-avatar {
  top: 4px;
  height: 72px;
  width: 72px;
  border-radius: 60px;
  right: auto;
}
div.skeleton-author {
  top: 20px;
  height: 20px;
  left: 95px;
  width: 150px;
}
div.skeleton-label {
  left: 95px;
  top: 46px;
  height: 10px;
  width: 69px;
}
div.skeleton-content-position {
  top: 100px;
  left: 15px;
  right: 15px;
  height: 21px;
  width: 240px;
}
div.skeleton-content-location {
  top: 129px;
  left: 15px;
  right: 15px;
  height: 16px;
  width: 144px;
}
div.skeleton-content-sector {
  top: 175px;
  left: 15px;
  right: 15px;
  height: 35px;
  width: 120px;
  border-radius: 80px;
}
div.skeleton-content-technology {
  top: 175px;
  left: 139px;
  right: 15px;
  height: 35px;
  width: 100px;
  border-radius: 80px;
}

@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

/* Job Cards */

.mobile-card-cta {
  text-align: right;
  padding-bottom: 8px;
  height: auto !important;
  width: 100% !important;
  display: block;
  position: relative !important;
}

@media only screen and (min-width: 767px) {
  .mobile-card-cta {
    display: none !important;
  }
}

.position,
.position-mobile {
  font-weight: 600;
}
@media only screen and (min-width: 767px) {
  .position-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 766px) {
  .position {
    display: none;
  }
}
.position-mobile {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.computing,
#computing {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #76ff00;
  margin-right: 8px;
}
.computing span {
  background-color: #187768;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.life-sciences,
#life-sciences {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ff0093;
  margin-right: 8px;
}
.life-sciences span {
  background-color: #7b10a3;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.industrial,
#industrial {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #00ffff;
  margin-right: 8px;
}
.industrial span {
  background-color: #005183;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.biotech,
#biotech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #00a7ff;
  margin-right: 8px;
}
.biotech span {
  background-color: #213b6c;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.advanced-engineering,
#advanced-engineering {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #1a1af2;
  margin-right: 8px;
}
.advanced-engineering span {
  background-color: #6f6f91;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.aerospace-drones,
#aerospace-drones {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #e67300;
  margin-right: 8px;
}
.aerospace-drones span {
  background-color: #5f4b37;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.advanced-materials,
#advanced-materials {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #f3ff64;
  margin-right: 8px;
}
.advanced-materials span {
  background-color: #707631;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.ai-ml,
#ai-ml {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #fd6c21;
  margin-right: 8px;
}
.ai-ml span {
  background-color: #6f2c0a;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.cybersecurity,
#cybersecurity {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ff1a00;
  margin-right: 8px;
}
.cybersecurity span {
  background-color: #5c231d;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.energy-climate-tech,
#energy-climate-tech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #fd5e94;
  margin-right: 8px;
}
.energy-climate-tech span {
  background-color: #8e2f50;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.food-agtech,
#food-agtech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #3bed34;
  margin-right: 8px;
}
.food-agtech span {
  background-color: #257921;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.hardware-electronics,
#hardware-electronics {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #c27efa;
  margin-right: 8px;
}
.hardware-electronics span {
  background-color: #5b2786;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.immersive-tech,
#immersive-tech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #00b0ff;
  margin-right: 8px;
}
.immersive-tech span {
  background-color: #16506a;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.medtech-healthtech,
#medtech-healthtech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ff6a6a;
  margin-right: 8px;
}
.medtech-healthtech span {
  background-color: #915656;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.mobility-tech,
#mobility-tech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #e03ca1;
  margin-right: 8px;
}
.mobility-tech span {
  background-color: #f2c0df;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.quantum-computing,
#quantum-computing {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #f400c4;
  margin-right: 8px;
}
.quantum-computing span {
  background-color: #4b0d3f;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.blockchain,
#blockchain {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #ff1a00;
  margin-right: 8px;
}
.blockchain span {
  background-color: #5c231d;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}
.energy-clean-tech,
#energy-clean-tech {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #fd5e94;
  margin-right: 8px;
}
.energy-clean-tech span {
  background-color: #8e2f50;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.industry-4-0-robotics,
#industry-4-0-robotics {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #baff00;
  margin-right: 8px;
}
.industry-4-0-robotics span {
  background-color: #506517;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

.web-3-blockchain,
#web-3-blockchain {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #00ffff;
  margin-right: 8px;
}
.web-3-blockchain span {
  background-color: #0d6f6f;
  padding: 12px 14px;
  border-radius: 80px;
  line-height: 40px;
}

@media only screen and (min-width: 768px) {
  .card-location-section {
    width: 50%;
    float: left;
    display: inline-block;
  }
  .card-classification-section {
    display: inline-block;
  }
  .mobile-card-cta {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .card-top-row-right {
    display: none;
  }
  .card-footer-left {
    float: left;
  }
  .card-post-date {
    text-align: left;
  }
}
.card-profile img {
  width: 100px;
  border-radius: 800px;
}
.card-profile-mobile img {
  width: 80px;
  border-radius: 100%;
}
.card-profile {
  position: fixed;
  top: 148px;
  right: 48px;
  text-align: center;
  width: 33vw;
  padding: 32px 0px 32px;
  box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
    -2px -2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}
.card-profile h1 {
  font-size: calc((2.8 - 1) * 1vw + 0.5rem);
  margin-bottom: 0px;
}
@media only screen and (max-width: 767px) {
  .card-profile {
    display: none;
  }
}
.card-profile-mobile {
  text-align: center;
  padding: 32px 0px 32px;
  box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
    -2px -2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}
@media only screen and (min-width: 768px) {
  .card-profile-mobile {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .card-profile {
    display: none;
  }
}
#company-name {
  font-size: 1.8rem;
}
#company-name-mobile {
  font-size: 1.8rem;
}
.card-opportunity {
  box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
    -2px -2px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 32px;
}
.fa {
  font-size: 24px !important;
  text-align: center;
  text-decoration: none;
  margin: 8px 2px;
  border-radius: 800px;
}
.fa:hover {
  opacity: 0.7;
}
.fa-facebook {
  background: #3b5998;
  color: white;
  padding: 9px 14px;
}
.fa-twitter {
  background: #55acee;
  color: white;
  padding: 8px;
}
.fa-linkedin {
  background: #007bb5;
  color: white;
  padding: 10px 11px;
}
.fa-youtube {
  background: #bb0000;
  color: white;
  padding: 12px;
}
.fa-instagram {
  background: #e1306c;
  color: white;
  padding: 10px 11px;
}
.post-date {
  max-width: 50%px;
  float: left;
}
.card-footer {
  padding-top: 32px;
}
/* Icon Orb */
.icon-orb {
  position: relative;
  top: 56px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-image: linear-gradient(150deg, #f063f0, #6553e8);
  box-shadow: -8px -8px 16px rgba(232, 83, 215, 0.64),
    8px 8px 16px rgba(101, 83, 232, 0.64);
}
.icon-orb p {
  text-align: center;
  padding-top: 24px;
}
#pricing-card-spacer {
  padding-bottom: 105px;
}
/* Forms */
.form-input {
  padding-bottom: 32px;
}
@media only screen and (min-width: 768px) {
  .search-filter-sort {
    position: fixed;
  }
}
.search-filter-sort input[type="text"] {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // background: #45474b;
  padding: 16px;
  border-radius: 8px;
  width: 268px;
  margin-bottom: 16px;
}
.search-filter-sort input[type="text"],
input[type="url"],
input[type="text"],
input[type="email"],
input[type="password"],
select {
  color: #ebebeb;
  // background: #45474b;
}
.search-filter-sort input[type="text"],
input[type="url"] select:focus {
  color: #ffffff;
  // background: #45474b;
}
::placeholder {
  color: #ebebeb;
}
.search-filter-sort select {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  // background: #45474b;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 14px;
}
.search-filter-sort select:focus {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  // background: #45474b;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 14px;
}
@media only screen and (max-width: 767px) {
  .company-sign-up-form input[type="text"],
  input[type="url"] {
    width: 80%;
  }
}
@media only screen and (min-width: 767px) {
  .form-checkboxes {
    column-count: 2;
  }
}
@media only screen and (min-width: 1200px) {
  #submit-search {
    border-radius: 0px 8px 8px 0px !important;
  }
}
#submit-search {
  background-image: linear-gradient(#8070f0, #4b37e0);
  border-radius: 8px;
  border: none;
  color: #ffffff;
  height: 55px;
  width: 100%;
}
.form-button {
  box-shadow: -4px -4px 4px rgba(96, 96, 96, 0.32),
    4px 4px 4px rgba(0, 0, 0, 0.32);
  background-image: linear-gradient(#8070f0, #4b37e0);
  border-radius: 80px;
  border: none;
  color: #ffffff;
  margin-top: 24px !important;
  padding: 16px 24px;
}
.form-button:hover {
  opacity: 0.64;
}
.sqs-block-button-element {
  box-shadow: -4px -4px 4px rgba(96, 96, 96, 0.32),
    4px 4px 4px rgba(0, 0, 0, 0.32);
  background-image: linear-gradient(#8070f0, #4b37e0);
  border-radius: 80px;
}
#yui_3_17_2_1_1616022218312_369 .sqs-block-button-element {
  box-shadow: -4px -4px 3px rgba(142, 107, 252, 0.32),
    4px 4px 4px rgba(85, 72, 183, 0.4);
  background-image: linear-gradient(#8070f0, #4b37e0);
}
p a {
  text-decoration: none !important;
}
.header-nav-folder-content a {
  line-height: 30px;
}

/* Fonts */
.ms-style .ms-signup-h1,
.ms-signup-label .ms-signup-reset {
  font-family: "Work Sans", sans-serif;
  font-weight: 500 !important;
}
.sqs-block-html a {
  background-image: none;
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 22px;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.header-nav-item a {
  font-size: 16px;
  font-weight: 500;
}
.card-button:hover {
  opacity: 0.7;
}
.card-plan-purchase-cta {
  font-size: 20px;
  font-weight: 600;
}
.large-pricing-terms {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  #block-yui_3_17_2_1_1615499266118_15078 {
    width: 80px;
    height: 80px;
  }
  #block-yui_3_17_2_1_1625837398281_11700 p {
    text-align: left !important;
  }
  .social-icon-alignment-right .sqs-svg-icon--list {
    text-align: left !important;
  }
}
.container-content {
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
  flex: 0 0 100%;
}

.container-content button {
  margin: auto;
  background-color: #5442d6;
  box-shadow: -4px -4px 4px rgba(96, 96, 96, 0.32),
    4px 4px 4px rgba(0, 0, 0, 0.32);
  background-image: linear-gradient(#8070f0, #4b37e0);
  border-radius: 80px;
  border: none;
  color: #ffffff;
  padding: 10px 44px;
  cursor: pointer;
}

.container-content button:hover {
  opacity: 0.64;
}

.companyImage {
  position: relative;
  padding-top: 30.25%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 14px;
}

.companyImage img {
  width: 100%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  opacity: 0.7;
  object-fit: cover;
}

.youtube {
  background-color: #000;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 14px;
}
.youtube img {
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  opacity: 0.7;
  object-fit: cover;
}
.youtube .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  opacity: 0.8;
  border-radius: 6px;
}
.youtube .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}
.youtube img,
.youtube .play-button {
  cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
  position: absolute;
}
.youtube .play-button,
.youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.youtube iframe {
  height: 100%;
  width: 100%;
  margin: auto;
  top: 0;
  left: 0;
}
#profile-media {
  object-fit: cover !important;
}
