.form {
  &-container {
    box-shadow: 2px 2px 8px rgba(96, 96, 96, 0.32),
      -2px -2px 8px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 32px;
    margin: 8px;
    background-color: #1f2227;
    background: transparent linear-gradient(112deg, #161a21 0%, #201d39 100%) 0%
      0% no-repeat padding-box;

    input {
      color: #ffffff;
      background-color: #2d2e3d !important;
      padding: 14px;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid #888888 !important;
      border-radius: 8px !important;
      margin-bottom: 16px;
    }
  }
}
