.nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background: #161a21;
  position: fixed;
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 72px;
  padding-right: 72px;
  pointer-events: auto;
  z-index: 1;
  width: 100%;

  &-mobile {
    display: none;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;

    .nav {
      &-content {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        z-index: -1;
      }

      &-list {
        position: relative;
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &-item {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Work Sans' !important;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0em;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1;
        margin: auto;
        text-align: center;
      }

      &-link {
        font-family: Work Sans !important;
        border-bottom: none !important;

        .active {
          border-bottom: none !important;
        }
      }
    }
  }

  &-menu {
    &-button {
      flex: 1;
      position: relative;
      width: 50px;
      height: 20px;
      background: transparent;
      border: none;
      cursor: pointer !important;
      padding: 2px;

      &-container {
        overflow: 2;
      }
    }

    &-icon {
      background: #fdfdfd;
      position: absolute;
      display: block;
      margin: auto;
      content: "";
      width: 100%;
      height: 1px;
      transition: transform 250ms cubic-bezier(0.2, 0.6, 0.3, 1),
        width 250ms cubic-bezier(0.2, 0.6, 0.3, 1);
      will-change: transform, width;
      left: 0;
    }

    &-icon:nth-of-type(1) {
      top: 0;
    }

    &-icon:nth-of-type(2) {
      bottom: 0;
    }
  }

  &-overlay {
    display: none;
    z-index: -1;
    background: #000;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-list {
    text-align: right;
    margin-left: auto;
    padding-left: 24px;
    justify-content: flex-end;
    display: flex;
  }

  &-container {
    &-logo {
      width: auto;
      font-size: 0;
    }

    z-index: 2;
  }

  &-item {
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    text-transform: uppercase;
    line-height: 2.4em;
    text-align: right;
    margin-left: 26px;
  }

  &-logo {
    width: 125px;
  }

  &-link {
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #fdfdfd;
    text-decoration: none;
    backface-visibility: hidden;
    padding: 0.1em 0;
    // font-size: 16px;
    font-weight: 500;
    display: block;
    font-size: calc((1.05 - 1) * 1.1vh + 0.9rem);

    &-logo {
      border-bottom: none !important;
    }

    &.active {
      border-bottom: 2px solid #fff;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .nav {
    padding: 10px !important;
    width: 100%;

    &-mobile {
      display: flex;
      padding: 10px !important;
    }

    &-desktop {
      display: none;
    }
  }
}
