.button {
  cursor: pointer;
  color: #fdfdfd;
  background-color: #6655df;
  border-color: #6655df;
  box-shadow: -4px -4px 4px rgb(96 96 96 / 32%), 4px 4px 4px rgb(0 0 0 / 32%);
  background-image: linear-gradient(#8070f0, #4b37e0);
  border-radius: 80px;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
  padding: 16px 26.72px;
  font-size: 16px;
  font-family: Work Sans;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  line-height: 1.2em;
  letter-spacing: 0.02em;
  font-size: 1rem;
}
