.horizontal-scroll {
  &-container {
    -ms-overflow-style: none;
    @media only screen and (max-width: 900px){
      scrollbar-width: none;
    };
    padding-bottom: 40px;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #494e57;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #494e57;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #32363c;
    }

    .company-object-result {
      scroll-snap-align: start;

    }
  }


  &-block {
    margin: 20px 0;
  }

  &-content {
    &-text {
      color: #a3a3a3;
      word-wrap: break-word;
      font-size: calc((1 - 1) * 1.2vh + 1rem);
      font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    }
  }
}

.profile-job-object {
  scroll-snap-align: start;
}
